import React, { useState, useEffect } from "react";
import {
  LayoutTwo,
  Review,
  SectionBox,
  ConfirmBox,
  SecureAreaBox,
} from "lotuscremationlibrary";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { call } from "src/services/Http";
import { Button, FormHelperText, Grid } from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ReviewTable from "../PostQuestionReview/ReviewTable";
import animation from '../../../../assets/img/FernWood_Loading_color-1.json'
import Lottie from "react-lottie";


export default function PostQuestView({ setTabval, setdisableTab, activeTab }) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [reviewData, setReviewData] = useState({});
  const [reviewerror, setReviewError] = useState("");
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmLoadingMsg, setConfirmLoadingMsg] = useState("");
  const [reviewIndex, setReviewIndex] = useState(null);
  const [progress, setProgress] = useState(0);
  const [pageType, setPageType] = useState("quest"); //it can be quest,review,conform,conformation
  const [checkExtraMailesCharges, setCheckExtraMailesCharges] = useState(false);

  const location = useLocation();
  const paths = location.pathname.split("/");
  const urlName = paths[2];
  const getUrlName = activeTab === "step5" ? "Great Western" : "Post Question";
  let isPostQueStart = localStorage.getItem("isPostQueStart");
  let isGwQueStart = localStorage.getItem("isGwQueStart");
  let isPostQuest = localStorage.getItem("isPostQuest");
  const navigate = useNavigate();
  const { id } = useParams();

  let is_Preneed = "";

  if (id === undefined) {
    is_Preneed = JSON.parse(localStorage.getItem("isPreneed"));
  } else {
    is_Preneed = JSON.parse(localStorage.getItem("isPreneed_" + id));
  }

  const setResponse = async (res, type) => {
    if (res.status === 200) {
      if (type === "confirm") {
        if (data.sections[data.sections.length - 2] !== undefined) {
          let newData = data;
          newData.sections[data.sections.length - 2].is_active = false;
          newData.sections[data.sections.length - 2].is_complete = true;
          setData(newData);
        }
        await setConfirmationData(res.data);
      } else if (type === "review") {
        await setReviewData(res.data);
      } else {
        await setProgress(
          ((res.data.progressbar.index - 1) / res.data.progressbar.total) * 100
        );
        await setData(res.data);
        if (
          res.data.is_review !== undefined &&
          ((res.data.sections[res.data.sections.length - 3].is_complete &&
            res.data.is_review === 1) ||
            (res.data.is_review === 0 && res.data.is_confirm === 1) ||
            (res.data.is_review === 0 && res.data.is_confirm === 0))
        ) {
          await getSectionQuest("Review");
        }
      }
    } else if (res.status === 400) {
      await getSectionQuest("Review");
      // await setPageType("review");
      await setReviewError(res.message);
    } else {
      alert("something went wrong");
    }
  };
  const makeCall = async (method, url, payload, type) => {
    setLoading(true);
    let globResponse = {};
    if (method === "POST") {
      await call(method, url, payload).then((response) => {
        globResponse = response;
      });
    } else {
      await call(method, url).then((response) => {
        globResponse = response;
      });
    }
    await setResponse(globResponse, type);
    setLoading(false);
  };
  const getQuestions = async () => {
    await setPageType("quest");
    const payload = {
      type: activeTab === "step5" ? 1 : 2,
      user_id: localStorage.getItem("rand_token"),
    };
    await makeCall("POST", "guest/questionnaire", payload);
  };
  const postQuestions = async (values, actions, is_next, is_skip = false) => {
    setPageType("quest");
    let payload = {
      type: activeTab === "step5" ? 1 : 2,
      is_next: is_next,
      answer:
        is_next && is_skip === false ? values.answer : data.question.answer,
      current_question_id: data.question.question_id,
      is_last: data.question.is_last,
      is_string: [1, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(
        data.question.control
      )
        ? 1
        : data.question.control === 4 &&
          [1, 5, 6].includes(data.question.control_handler)
        ? 1
        : 0,
      address:
        is_next && values.address !== undefined && is_skip === false
          ? values.address
          : "",
      isFileSelected:
        is_next && is_skip === false && values.files !== undefined
          ? true
          : false,
      user_id: localStorage.getItem("rand_token"),
    };
    await makeCall("POST", "guest/questionnaire", payload);
    // if (
    //   (data.question.is_last === 1 || data.question.is_last === true) &&
    //   (is_next === true || is_next === 1)
    // ) {
    //   await getSectionQuest("Review");
    // }
  };
  const getSectionQuest = async (section) => {
    if (section === "Review") {
      await setPageType("review");
      await makeCall(
        "POST",
        "guest/review",
        {
          type: activeTab === "step5" ? 1 : 2,
          user_id: localStorage.getItem("rand_token"),
        },
        "review"
      );
    } else if (section === "Confirm") {
      await setPageType("confirm");
      const payload = {
        type: activeTab === "step5" ? 1 : 2,
        is_review: true,
        user_id: localStorage.getItem("rand_token"),
      };
      await makeCall("POST", "guest/update_review", payload, "confirm");
    } else {
      await setPageType("quest");
      const payload = {
        type: activeTab === "step5" ? 1 : 2,
        section_id: section,
        user_id: localStorage.getItem("rand_token"),
      };
      await makeCall("POST", "guest/questions_by_section", payload);
    }
  };
  const getConfirmation = async () => {
    setLoading(true);
    setConfirmLoadingMsg(
      "Thank you for completing your application with Fernwood. Your application has now been submitted and will soon be sent to you via email for your signature."
    );

    const reviewPDFurl =
      activeTab === "step5"
        ? "guest/gen_review_pdf"
        : "guest/gen_gw_review_pdf";
    const payload = {
      type: activeTab === "step5" ? 1 : 2,
      user_id: localStorage.getItem("rand_token"),
    };

    const confirmresponse = await call("POST", reviewPDFurl, payload);

    if (confirmresponse.status === 200) {
      if (activeTab === "step6") {
        setLoading(false);
        // props.setPreNeedDoneChk(true);
        setTabval("step4");
        setdisableTab([
          "step2",
          "step3",
          "step5",
          parseInt(is_Preneed) === 1 ? "" : "step6",
          "step7",
        ]);
      } else {
        setCheckExtraMailesCharges(confirmresponse.data[0].is_extra_miles);
        setPageType("confirmation");
        setConfirmLoadingMsg("");

        let newData = data;
        newData.sections[data.sections.length - 2].is_active = false;
        newData.sections[data.sections.length - 1].is_complete = true;
        setData(newData);
        setLoading(false);
        // if (confirmresponse.data[0].is_extra_miles) {
        //   navigate("/app/extramailespayment");
        // }
      }
    }
    // await makeCall("POST", reviewPDFurl, payload);
  };
  const setSecureArea = () => {
    activeTab === "step5"
      ? localStorage.setItem("isGwQueStart", true)
      : localStorage.setItem("isPostQueStart", true);
    getQuestions();
  };

  const handleClick = () => {
    if (checkExtraMailesCharges) {
      setTabval("step7");
      setdisableTab([
        "step2",
        "step3",
        "step4",
        parseInt(is_Preneed) ? null : "step6",
      ]);
    } else {
      localStorage.removeItem(is_Preneed);
      if (id === undefined) {
        localStorage.removeItem("rand_token");
      }
      navigate("/app/counselors");
    }
  };

  useEffect(() => {
    const getTheme = async () => {
      await call("get", "get_theme/2").then(async ({ data, status }) => {
        // setImage(data.image);
        // setColor(data.font_color);
        // Helper.setItem("bgImage", data.image);
        localStorage.setItem("PostBgImage", data.image);
        localStorage.setItem("PostBgFont", data.font_color);
        // console.log(data);
      });
    };
    getTheme();
    getQuestions().then(async (response) => {
      await setLoading(false);
    });
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="postque">
      <Grid container direction={"row"} className="main-body">
        {isPostQuest == 1 ? (
          <ReviewTable />
        ) : (
          <>
            <Grid
              item
              md={4}
              className="scroll-md"
              style={{ overflowY: "auto" }}
            >
              {data.sections && (
                <SectionBox
                  section={data.sections}
                  getSection={getSectionQuest}
                  progress={progress}
                  pageType={pageType}
                  isFromAdmin={true}
                />
              )}
            </Grid>
            {(isGwQueStart === null && urlName === "gwquest") ||
            (isPostQueStart === null && urlName === "postquestv1") ? (
              <Grid item md={8} className="scroll-md theme-bg-color">
                <SecureAreaBox setSecureArea={setSecureArea} />
              </Grid>
            ) : (
              <Grid
                item
                md={8}
                className="scroll-md theme-bg-color"
                style={{ height: "91vh", overflowY: "auto" }}
              >
                {isLoading && (
                  <Grid container justifyContent={"center"}  style={{ width: '100px', height: '100px', margin: '0 auto',}}>

                    {/* <img
                      src="/img/loader.gif"
                      height={100}
                      width={100}
                      alt="Loading"
                    /> */}
                     <Lottie options={defaultOptions} />
                  </Grid>
                )}
                {isLoading && confirmLoadingMsg !== "" && (
                  <Grid container justifyContent={"center"}>
                    <FormHelperText style={{ color: "#ffeb3b" }}>
                      {confirmLoadingMsg}
                    </FormHelperText>
                  </Grid>
                )}
                {pageType === "quest" && !isLoading && (
                  <LayoutTwo
                    loading={isLoading}
                    data={data}
                    onSubmitForm={postQuestions}
                    isFromAdmin={true}
                  />
                )}
                {pageType === "review" && !isLoading && (
                  <Review
                    reviewSection={data.sections[data.sections.length - 2]}
                    prevSection={
                      data.sections[data.sections.length - 3].section_id
                    }
                    type={urlName === "gwquest" ? 2 : 1}
                    confirmed={
                      data.is_confirm === 0
                        ? // data.sections[data.sections.length - 1].is_complete === 1
                          true
                        : false
                    }
                    reviewData={reviewData}
                    reviewerror={reviewerror}
                    onSectionGet={getSectionQuest}
                    isFromAdmin={true}
                  />
                )}
                {pageType === "confirm" && !isLoading && (
                  <ConfirmBox
                    getConfirmation={getConfirmation}
                    getSection={getSectionQuest}
                    isFromAdmin={true}
                  />
                )}
                {pageType === "confirmation" && !isLoading && (
                  <Grid
                    container
                    style={{
                      marginTop: 30,
                      textAlign: "Center",
                    }}
                  >
                    <Grid item md={12} xs={12}>
                      <CheckCircleOutlinedIcon
                        sx={{ fontSize: 100, color: "red" }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} style={{ color: "yellow" }}>
                      Your application is submitted successfully. You got mail
                      containing application pdf for review.
                      <br /> Please check your inbox.
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleClick}
                        style={{ margin: "3%" }}
                      >
                        Complete
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}
