import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Box,
  makeStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Chip,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import * as Messages from "src/constants/Messages/index";

import { Formik } from "formik";
import { CKEditor } from "ckeditor4-react";
import { AttachFile } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import EmailMessageField from "./EmailMessageField";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  fieldGroup: {
    marginBottom: theme.spacing(3),
  },
  editor: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    "& .ck-editor__main": {
      minHeight: "200px",
    },
  },
  attachmentContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  attachmentIcon: {
    marginRight: theme.spacing(1),
  },
  attachmentLabel: {
    flex: 1,
  },
}));

const validationSchema = Yup.object().shape({
  to: Yup.array()
    .min(1, "At least one recipient is required") // Ensure at least one recipient is selected
    .of(Yup.string().email("Invalid email address"))
    .required(Messages.REQUIRED),
  cc: Yup.array()
    .of(Yup.string().email("Invalid email"))
    // .required(Messages.REQUIRED)
    ,
  bcc: Yup.array()
    .of(Yup.string().email("Invalid email"))
    // .required(Messages.REQUIRED)
    ,
  emailSubject: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  emailMessage: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  docFormName: Yup.array(),
  // .min(1, "Please select at least one document") // Adjust validation as needed
  // .required("Please select at least one document"),
  docFormAttachment: Yup.mixed(),
});

const EmailDetails = ({
  //   initialEmailDetailsValues,
  userList,
  sendEmail,
  emailtemplatelist,
  docuforms,
  id,
}) => {
  const classes = useStyles();
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);

  const [emailMessage, setEmailMessage] = useState("");

  const initialEmailDetailsValues = {
    to: [],
    cc: [],
    bcc: [],
    userId: id, //param
    emailSubject: "",
    emailMessage: "",
    docFormName: [],
    docFormAttachment: [],
    saveAsTemplate: 0,
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Formik
          initialValues={initialEmailDetailsValues}
          validationSchema={validationSchema}
          // enableReinitialize={true}
          // onSubmit={async (values, actions) => {
          //   console.log("submit data::", values);
          //   await sendEmail(values, actions);
          //   setSelectedFilesCount(0);
          //   actions.resetForm();
          //   actions.setSubmitting(false);
          // }}

          onSubmit={async (values, actions) => {
            console.log("submit data::", values);
            try {
              await sendEmail(values, actions);
              setSelectedFilesCount(0);
              actions.resetForm();
              actions.setSubmitting(false);
            } catch (error) {
              console.error("Error sending email:", error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            submitForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={userList.map((option) => option.email)}
                    freeSolo
                    value={values.to || []} // Ensure values.to is an array or default to an empty array
                    onChange={(event, newValue) => {
                      setFieldValue("to", newValue); // Update Formik value on change
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="To"
                        name="to"
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        className={classes.fieldGroup}
                        error={touched.to && Boolean(errors.to)}
                        helperText={touched.to && errors.to}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={userList.map((option) => option.email)}
                    freeSolo
                    value={values.cc || []} // Ensure values.to is an array or default to an empty array
                    onChange={(event, newValue) => {
                      setFieldValue("cc", newValue); // Update Formik value on change
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="CC"
                        name="cc"
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        className={classes.fieldGroup}
                        error={touched.cc && Boolean(errors.cc)}
                        helperText={touched.cc && errors.cc}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={userList.map((option) => option.email)}
                    freeSolo
                    value={values.bcc || []} // Ensure values.to is an array or default to an empty array
                    onChange={(event, newValue) => {
                      setFieldValue("bcc", newValue); // Update Formik value on change
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="BCC"
                        name="bcc"
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        className={classes.fieldGroup}
                        error={touched.bcc && Boolean(errors.bcc)}
                        helperText={touched.bcc && errors.bcc}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="free-solo-demo"
                    options={emailtemplatelist.map(
                      (option) => option.email_subject
                    )}
                    freeSolo
                    value={values.emailSubject}
                    forcePopupIcon={false}
                    name="emailSubject"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subject/Template"
                        variant="outlined"
                        // value={values.subject}
                        fullWidth
                        className={classes.fieldGroup}
                        error={
                          touched.emailSubject && Boolean(errors.emailSubject)
                        }
                        helperText={touched.emailSubject && errors.emailSubject}
                      />
                    )}
                    // disableClearable
                    onChange={(event, value) => {
                      if (!value) {
                        setFieldValue("emailMessage", "");
                        setEmailMessage("");
                        return;
                      }
                      setFieldValue("emailSubject", value);
                      const selectedTemplate = emailtemplatelist.find(
                        (template) => template.email_subject === value
                      );

                      if (selectedTemplate) {
                        setFieldValue(
                          "emailMessage",
                          selectedTemplate.email_message
                        );
                        setEmailMessage(selectedTemplate.email_message);
                      } else {
                        //setFieldValue("emailSubject", "");
                        setFieldValue("emailMessage", "");
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue("emailSubject", newInputValue);
                    }}
                  />
                </Grid>

                <EmailMessageField
                  emailMessage={emailMessage}
                  setEmailMessage={setFieldValue}
                  touched={touched}
                  errors={errors}
                  values={values}
                />

                {/* <Grid item xs={12}>
                  <Box className={classes.fieldGroup}>
                    <CardHeader title="Message" />
                    <CKEditor
                      name="emailMessage"
                      value={values.emailMessage}
                      // data={values.emailMessage}
                      // initData={values.emailMessage}
                      onBlur={(event) => {
                        setFieldValue("emailMessage", event.editor.getData());
                      }}
                      onChange={(event) => {
                        setFieldValue("emailMessage", event.editor.getData());
                      }}
                      config={{
                        extraPlugins: "docprops",
                        removePlugins: "about,image,easyimage",
                        fullPage: false,
                        allowedContent: true,
                        formatTags: "p;h1;h2;h3;pre;span;",
                        height: 320,
                      }}
                    />
                    {touched.emailMessage && errors.emailMessage && (
                      <Typography variant="body2" color="error">
                        {errors.emailMessage}
                      </Typography>
                    )}
                  </Box>
                </Grid> */}

                {/* <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-document-label">
                      Select Document
                    </InputLabel>
                    <Select
                      labelId="select-document-label"
                      id="select-document"
                      name="selectedDocument"
                      value={values.selectedDocument}
                      onChange={handleChange}
                      label="Select Document"
                      fullWidth
                      className={classes.fieldGroup}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {docuforms.map((document) => (
                        <MenuItem key={document.id} value={document.id}>
                          {document.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={6} md={6} style={{ padding: "0 12px" }}>
                  <FormControl
                    variant="outlined"
                    className="statusdocformselectControl"
                    fullWidth
                  >
                    <InputLabel id="select-document-label">
                      Select Document
                    </InputLabel>
                    <Select
                      id="select-document"
                      name="docFormName"
                      label="On Document Form"
                      fullWidth
                      error={Boolean(touched.docFormName && errors.docFormName)}
                      multiple
                      value={values.docFormName || []}
                      // value={values.on_status !== 0 ? values.selectedDocument : []}
                      onChange={(e) => {
                        setFieldValue("docFormName", e.target.value);
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                        }
                        return selected.length + " selected";
                      }}
                      inputProps={{
                        name: "users",
                        id: "outlined-age-native-simple",
                      }}
                      // disabled={
                      //   values.on_status === "" ||
                      //   values.on_status === 0 ||
                      //   values.on_status === undefined
                      // }
                    >
                      <MenuItem value="">
                        <em>Select Document Form</em>
                      </MenuItem>

                      {docuforms.map((docuformslist, frm_index) => (
                        <MenuItem
                          key={`form_${frm_index}`}
                          value={docuformslist.id}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(values.docFormName) &&
                              values.docFormName.indexOf(docuformslist.id) > -1
                            }
                          />
                          <div className="MuiListItemText-root MuiListItemText-multiline">
                            <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                              {docuformslist.name}
                            </span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(touched.docFormName && errors.docFormName) ? (
                      <FormHelperText className="Mui-error">
                        {errors.docFormName}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.attachmentContainer}>
                  <Box display="flex" alignItems="center">
                    <AttachFile className={classes.attachmentIcon} />
                    <Typography className={classes.attachmentLabel}>
                      Attachments :
                    </Typography>
                  </Box>
                  <input
                    type="file"
                    name="docFormAttachment"
                    onChange={(event) => {
                      const files = event.target.files;
                      const fileArray = Array.from(files); // Convert FileList to array
                      setFieldValue("docFormAttachment", files);
                      setSelectedFilesCount(files.length);
                    }}
                    multiple
                    accept="application/pdf,image/*"
                    style={{
                      display: "none",
                    }}
                    id="file-input"
                    error={
                      touched.docFormAttachment &&
                      Boolean(errors.docFormAttachment)
                    }
                    helperText={
                      touched.docFormAttachment && errors.docFormAttachment
                    }
                  />{" "}
                  <label htmlFor="file-input">
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      className={classes.attachmentButton}
                    >
                      Upload File
                    </Button>
                  </label>
                  <Typography>{selectedFilesCount} files selected</Typography>
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end" marginTop={3}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !id}
                  style={{ marginLeft: "16px" }}
                >
                  SEND
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !id}
                  style={{ marginLeft: "16px" }}
                  onClick={() => {
                    setFieldValue("saveAsTemplate", 1);
                    submitForm();
                  }}
                >
                  SAVE AS A TEMPLATE
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default EmailDetails;
