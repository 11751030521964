import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { AppBar, Container } from "@material-ui/core";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Page from "src/components/Page";
import TabPanel from "src/components/TabPanel";
import AdminUserListView from "src/views/User/UserListView";
import FrontUserListView from "src/views/User/UserFrontListView";
import AdminUserToolbar from "src/views/User/UserListView/Toolbar";
import FrontUserToolbar from "src/views/User/UserFrontListView/Toolbar";

import { SET_USER_ACTIVE_TAB } from "src/actions";
import { useDispatch, useSelector } from "react-redux";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Users List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  page: {
    display: "flex",
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

export default function UsersTabView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const value = useSelector((state) => state?.session?.userActiveTab);
  const authuser = useSelector((state) => state?.session?.user);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatch({
      type: SET_USER_ACTIVE_TAB,
      payload: { userActiveTab: newValue },
    });
  };

  return (
    <Page title="Users" className={classes.page}>
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        {value === "admin_user" && <AdminUserToolbar authuser={authuser} />}
        {value === "front_user" && <FrontUserToolbar authuser={authuser} />}
        {/* {value === "contact_user" && <ContactsToolbar authuser={authuser} />} */}
        <AppBar position="static" color="inherit" className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="admin_user"
              label="Admin Users"
              wrapped
              {...a11yProps("admin_user")}
            />
            <Tab
              value="front_user"
              label="Users"
              {...a11yProps("front_user")}
            />
            {/* <Tab
              value="contact_user"
              label="Contacts"
              {...a11yProps("contact_user")}
            /> */}
          </Tabs>
        </AppBar>
        <div>
          <TabPanel value={value} index="admin_user">
            <AdminUserListView />
          </TabPanel>
          <TabPanel value={value} index="front_user">
            <FrontUserListView />
          </TabPanel>
        </div>
      </Container>
    </Page>
  );
}
