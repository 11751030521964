import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Switch, // Import Switch component from Material-UI
} from "@material-ui/core";
import "react-phone-input-2/lib/material.css";
import * as Messages from "src/constants/Messages/index";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectControl: {
    minWidth: "100%",
  },
}));

const AddUserSchema = Yup.object().shape({
  days: Yup.number().required(Messages.REQUIRED),
  active: Yup.boolean(), // Add validation for the switch
});

const ReviewSetting = () => {
  const classes = useStyles();
  const id = useParams();

  const initialValues = {
    user_id: id,
    days: "",
    active: false, // Initial state of the switch
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        console.log(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="days"
                      label="NO. OF DAYS"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.days}
                      error={Boolean(touched.days && errors.days)}
                      margin="normal"
                      helperText={touched.days && errors.days}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Switch
                        name="active"
                        checked={values.active}
                        onChange={(e) =>
                          setFieldValue("active", e.target.checked)
                        }
                        color="primary"
                      />
                      <Box ml={1}>Active</Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default ReviewSetting;
