import React, { useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import ServicesDetails from "../Components/ServicesDetails";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
/*import Toolbar from "./Toolbar";*/

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  addonDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddServicesView = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    service_name: "",
    service_description: "",
    image: "",
    file: null,
    category_id: "",
    price: "",
    status: "",
    tax_type: "",
    taxable_amount: "",
  });
  const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    { name: "Add-ons", url: "/app/addons" },
    { name: "Add Add-on", url: "/" },
  ];

  return (
    <Page className={classes.root} title="Add Add-on">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* <Grid item md={2} xs={12}></Grid> */}
          <Grid item md={12} xs={12}>
            <BreadcrumbsAdmin urlList={breadUrlList} />

            <ServicesDetails
              formValues={values}
              setValues={setValues}
              setSnackbar={props.setSnackbar}
              mode={"Add"}
              addonClass={classes.addonDetail}
            />
          </Grid>
          {/* <Grid item md={2} xs={12}></Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddServicesView);
