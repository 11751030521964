import React, { useState, useEffect } from "react";
import { Link, Link as RouterLink, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactJson from "react-json-view";

import {
  Box,
  Button,
  Card,
  makeStyles,
  Container,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Page from "src/components/Page";
import Tooltip from "@material-ui/core/Tooltip";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import { parse } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",

    paddingTop: theme.spacing(3),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const ActivityLogs = ({ deletest, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [logProperties, setLogProperties] = useState("");
  const classes = useStyles();
  const [responsive] = useState("standard");
  const { id } = useParams(1);
  const [Loading, setLoading] = useState(true);
  const [activityLogList, setActivityLogList] = useState([]);
  const ActivityLogList = async () => {
    try {
      const response = await Http.call("GET", "admin/getLog");
      setLoading(false);
      await setActivityLogList(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    // { name: "Application List", url: "/app/counselors" },
    { name: "Activity Logs", url: "/" },
  ];

  const handleClickOpen = (event, index, value) => {
    //setLogProperties(event.currentTarget.value);
    //setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Activitylog.csv" },
    selectableRowsHideCheckboxes: true,
  };

  useEffect(() => {
    ActivityLogList();
  }, []);

  const columns = [
    {
      name: "action",
      label: "Details",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <div className="ta-flex">
            {activityLogList[dataIndex].properties !== "" ? (
              <Tooltip disableFocusListener title="View Details">
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    setLogProperties(activityLogList[dataIndex].properties);
                    setOpen(true);
                  }}
                >
                  <VisibilityOutlinedIcon />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        ),
      },
    },
    {
      name: "created_at",
      label: "Date & Time",
    },
    {
      name: "subject_id",
      label: "ID",
    },
    {
      name: "log_name",
      label: "Log Title",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRenderLite: (dataIndex) => (
          <div className="ta-flex">
            {activityLogList[dataIndex].log_link !== "" ? (
              <Link
                // component={RouterLink}
                to={`${activityLogList[dataIndex].log_link}`}
                key={dataIndex.id}
              >
                {activityLogList[dataIndex].log_name}
              </Link>
            ) : (
              activityLogList[dataIndex].log_name
            )}
          </div>
        ),
      },
    },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "causer_name",
      label: "Username",
    },
  ];

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Activity Logs">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Grid container spacing={3}>
            <Grid item>
              <Typography variant="h3" component="h3">
                Activity Logs
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to={`/app/counselors/`}
            >
              Back
            </Button>
          </Grid>
        </Box>
        <Card {...rest}>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <MUIDataTable
                data={activityLogList}
                columns={columns}
                options={options}
              />
            </Box>
          </PerfectScrollbar>
        </Card>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <ReactJson src={logProperties} theme="monokai" />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ActivityLogs;
