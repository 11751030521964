import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";

const useStyles = makeStyles((theme) => ({
  selectControl: {
    minWidth: "100%",
  },
}));

const selection_types = [
  {
    id: 0,
    value: 0,
    name: "Single",
  },
  {
    id: 1,
    value: 1,
    name: "Multiple",
  },
];

const AddCategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .matches(/^[A-Za-z ]*$/, Messages.ONLY_ALPABATES)
    .required(Messages.REQUIRED),
  selection_type: Yup.string().required(Messages.REQUIRED),
});
const EditCategoryDetail = (props) => {
  const classes = useStyles();
  const { formValues, updateCategory } = props;
  const initialValues = {
    name: formValues.name,
    description: formValues.description,
    selection_type: formValues.selection_type,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddCategorySchema}
      //onSubmit={updateProfile}
      onSubmit={async (values, actions) => {
        await updateCategory(values, actions);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                subheader="The information can be edited"
                title="Edit Category"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name="name"
                      label="Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      margin="normal"
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      margin="normal"
                      variant="outlined"
                      error={Boolean(touched.description && errors.description)}
                      multiline
                      rows={3}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                      margin="normal"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Selection Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Selection Type"
                        name="selection_type"
                        value={values.selection_type}
                        onChange={handleChange}
                        error={Boolean(
                          touched.selection_type && errors.selection_type
                        )}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Select Selection Type</em>
                        </MenuItem>

                        {selection_types.map((selection_type, index) => (
                          <MenuItem key={index} value={selection_type.id}>
                            {selection_type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditCategoryDetail;
