import * as yup from "yup";
export const getSchema = (type, question_id = null) => {
  switch (type) {
    case 3:
      return yup.object().shape({
        answer: yup
          .array()
          .required("Required") // these constraints are shown if and only if inner constraints are satisfied
          .min(1, "Required"),
      });
      break;
    case 4:
      return yup
        .object()
        .shape({
          answer: yup.string().required("Required").nullable(),
        })
        .required("Required")
        .nullable();
      break;
    case 5:
        return yup.object().shape({
          answer: yup.string().required("Required").test("len","Maximum 500 character allowed.",(val) => val && val.toString().length <= 500),
        });
        break;
    case 9:
      return yup.object().shape({
        answer: yup.string().email("Invalid email format").required("Required"),
      });
      break;
    case 10:
      return yup.object().shape({
        answer: yup.string().required("Required"),
      });
      break;
    case 11:
      if (question_id === 13) {
        return yup.object().shape({
          answer: yup
            .number()
            .positive()
            .integer()
            .min(1)
            .max(5)
            .required("Required"),
        });
      } else {
        return yup.object().shape({
          answer: yup.number().min(0).required("Required").test("len","Maximum 20 character allowed.",(val) => val && val.toString().length <= 20),
        });
      }
      break;
    case 13:
      return yup.object().shape({
        answer: yup
          .date()
          .nullable()
          .transform((originalValue, originalObject) => {
            // Transform empty string to null to handle cases where the date is not provided
            return originalValue === '' ? null : originalValue;
          })
          .test('dob', 'The date should be less than 18 years ago', function (
            value
          ) {
            if (!value) {
              // If the date is null or undefined, validation passes
              return true;
            }
      
            const dob = new Date(value);
            const eighteenYearsAgo = new Date();
            eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      
            return dob <= eighteenYearsAgo;
          })
          .required('Required'),
      });
      //  case 13:
      // return yup.object().shape({
      //   answer: yup
      //     // .date()
      //     .string()
      //     .matches(/^\d{2}[./-]\d{2}[./-]\d{4}$/, "Invalid format")
      //     .nullable()
      //     .test(
      //       "dob",
      //       "The date should be less than 18 year",
      //       function (value, ctx) {
      //         const dob = new Date(value);
      //         const validDate = new Date();
      //         const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
      //         return !valid ? ctx.createError() : valid;
      //       }
      //     )
      //     .required("Required"),
      // });
    case 14:
      return yup.object().shape({
        firstname: yup.string().required("Required").test("len","Maximum 100 character allowed.",(val) => val && val.toString().length <= 100),
        middlename: yup.string().test("len","Maximum 100 character allowed.",(val) => val && val.toString().length <= 100).nullable(),
        lastname: yup.string().required("Required").test("len","Maximum 100 character allowed.",(val) => val && val.toString().length <= 100),
      });
    case 15:
      return yup.object().shape({
        answer: yup
          .number()
          .positive()
          .integer()
          .min(1)
          .max(100)
          .required("Required"),
      });
    case 17:
      return yup.object().shape({
        answer: yup
          .number()
          .positive()
          .integer()
          .required("Required")
          .test(
            "len",
            "Invalid ssn no",
            (val) => val && val.toString().length === 9
          ),
      });
    case 6:
      return yup.object().shape({
        answer: yup.string().required("Required").nullable(),
      });
    default:
      return yup.object().shape({
        answer: yup.string().required("Required").test("len","Maximum 100 character allowed.",(val) => val && val.toString().length <= 100).nullable(),
      });
      break;
  }
};
