import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Box,
} from "@material-ui/core";

import Cancelbtn from "src/components/common/Cancelbtn";
import * as Messages from "src/constants/Messages/index";
import { useLocation } from "react-router";
import { CKEditor } from "ckeditor4-react";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "40vw",
    },
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  fieldGroup: {
    display: "flex",
    alignItems: "center",
  },
  fieldHint: {
    margin: theme.spacing(1, 0),
  },
  tags: {
    marginTop: theme.spacing(1),
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  dateField: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const EmailDetails = (props) => {
  const {
    className,
    handleChange,
    templateData,
    updateEmailtemplatedetails,
    addEmailtemplatedetails,
    ...rest
  } = props;

  const classes = useStyles();
  const location = useLocation();
  const paths = location.pathname.split("/");
  const email_mode_type = paths[4];

  const initialEmailDetailsValues = {
    id: templateData.id,
    email_code: templateData.code,
    email_subject: templateData.email_subject,
    email_title: templateData.email_title,
    email_message: templateData.email_message,
    type: templateData.type,
  };

  const EmailDetailsSchema = Yup.object().shape({
    email_subject: Yup.string()
      .min(2, Messages.TOO_SHORT)
      .required(Messages.REQUIRED),
    email_title: Yup.string()
      .min(2, Messages.TOO_SHORT)
      .required(Messages.REQUIRED),
    email_message: Yup.string()
      .min(20, Messages.TOO_SHORT)
      .required(Messages.REQUIRED),
  });
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader />
      <CardContent>
        <Formik
          initialValues={initialEmailDetailsValues}
          validationSchema={EmailDetailsSchema}
          onSubmit={async (values, actions) => {
            email_mode_type === "add"
              ? await addEmailtemplatedetails({
                  ...values,
                })
              : await updateEmailtemplatedetails({
                  ...values,
                });
            actions.setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={classes.formGroup}>
                  <div className={classes.fieldGroup}>
                    <TextField
                      error={Boolean(
                        touched.email_subject && errors.email_subject
                      )}
                      className={classes.dateField}
                      label="Template Subject*"
                      name="email_subject"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setFieldValue("email_subject", e.target.value);
                          setFieldValue("email_code", e.target.value);
                        } else {
                          setFieldValue("email_subject", "");
                          setFieldValue("email_code", "");
                        }
                      }}
                      value={values.email_subject}
                      helperText={touched.email_subject && errors.email_subject}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.email_title && errors.email_title)}
                      className={classes.dateField}
                      label="Template Title*"
                      name="email_title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email_title}
                      helperText={touched.email_title && errors.email_title}
                      variant="outlined"
                    />

                    <TextField
                      className={classes.dateField}
                      label="Template Type"
                      name="email_code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled
                      value={values.email_code}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <CardHeader title="Email Description" />
                  <CKEditor
                    name="email_message"
                    config={{
                      extraPlugins: "docprops",
                      removePlugins: "about,image,easyimage",
                      fullPage: false,
                      allowedContent: true,
                      formatTags: "p;h1;h2;h3;pre;span;",
                      height: 320,
                    }}
                    initData={values.email_message}
                    onBlur={(event) => {
                      setFieldValue("email_message", event.editor.getData());
                    }}
                    onChange={(event) => {
                      setFieldValue("email_message", event.editor.getData());
                    }}
                  />
                </div>
                {errors.email_message && touched.email_message && (
                  <div style={{ color: "red", margin: ".8rem 0" }}>
                    {errors.email_message}
                  </div>
                )}
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Cancelbtn />
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {email_mode_type === "add" ? "Save" : "Update"}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};

EmailDetails.propTypes = {
  className: PropTypes.string,
};

export default EmailDetails;
