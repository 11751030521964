import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import { connect } from "react-redux";
import * as Yup from "yup";
import * as Messages from "src/constants/Messages/index";
import { RadioGroup } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import images from "react-payment-inputs/images";
import { useParams } from "react-router-dom";
import * as Http from "src/services/Http";
import ThankYouView from "./ThankYouView";
import ScreenLoader from "src/components/ScreenLoader";
import "src/styles/custom.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "src/components/Page";
/*import PaymentLoader from "./Components/PaymentLoader";*/

const validationSchema = Yup.object().shape({
  holderName: Yup.string().required(Messages.REQUIRED),
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
}));

const validateForm = (values, meta) => {
  let errors = {};
  if (values.holderName === "") {
    errors.holderName = Messages.REQUIRED;
  }
  if (meta.erroredInputs.cardNumber) {
    errors.cardNumber = meta.erroredInputs.cardNumber;
  }
  if (meta.erroredInputs.expiryDate) {
    errors.expiryDate = meta.erroredInputs.expiryDate;
  }
  if (meta.erroredInputs.cvc) {
    errors.cvc = meta.erroredInputs.cvc;
  }
  return errors;
};

export const PaymentView = (props) => {
  let user_id = "";
  let is_Preneed = "";

  const { id } = useParams();
  if (id === undefined) {
    is_Preneed = localStorage.getItem("isPreneed");
    user_id = JSON.parse(localStorage.getItem("rand_token"));
  } else {
    is_Preneed = localStorage.getItem("isPreneed_" + id);
    user_id = id;
  }

  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [isAgree, setisAgree] = useState(false);
  const handleChangeChk = (arg) => {
    setisAgree(arg.target.checked);
  };
  const [Discount, setDiscount] = useState(0);
  const [PromoCode, setPromocode] = useState("");
  const [resStatus, setresStatus] = useState(null);
  const [resMessage, setresMessage] = useState("");
  const [promoLoader, setpromoLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [packageData, setPackageData] = useState({});
  const [discountDetaile, setdiscountDetaile] = useState(null);
  const [cirtificateCount, setCirtificateCount] = useState(0);
  const [certificatePrice, setCertificatePrice] = useState(0);
  const { categoryData, crtPrice } = props;
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;

  let total =
    packageData.price + cirtificateCount * certificatePrice - certificatePrice;
  serviceData.length > 0 &&
    serviceData.map((item, index) => {
      if (item !== null) {
        total = total + item.price;
      }
    });

  const CheckCode = async () => {
    setpromoLoader(true);

    let category = [];
    serviceData.length > 0 &&
      serviceData.map((item, index) => (category[index] = item.id));

    if (Discount !== 0) {
      setDiscount(0);
      setresStatus({});
      setresMessage("");
      setPromocode("");
      setdiscountDetaile(null);
    } else {
      const response = await Http.call("POST", "discount", {
        promocode: PromoCode,
        package_id: packageData.id,
        addons_ids: category,
        no_of_cert: cirtificateCount,
        flag: "admin",
      });
      setresStatus(response.status);
      setresMessage(response.message);
      if (response.status === 200) {
        setdiscountDetaile(response.data);
        setDiscount(response.data.discount_amount);
      }
    }
    setpromoLoader(false);
    return true;
  };

  const makePayment = async (PaymentInfo) => {
    setLoading(true);

    let addOns = [];
    serviceData.length > 0 &&
      serviceData.map((item, index) =>
        addOns.push({
          id: item.id,
          quantity: item.quantity ? item.quantity : 0,
          state: item.state !== undefined ? item.state : "",
          address: item.address !== undefined ? item.address : "",
        })
      );
    let body = {
      card_number: parseInt(PaymentInfo.cardNumber.replace(/ /g, ""), 10),
      card_name: PaymentInfo.holderName,
      card_expiry: PaymentInfo.expiryDate.replace(/ /g, ""),
      card_cvv: parseInt(PaymentInfo.cvc),
      package_id: packageData.id,
      addons_ids: addOns,
      discount_id: discountDetaile !== null ? discountDetaile.discount_id : 0,
      user_id: user_id,
      no_of_cert: cirtificateCount,
      flag: "admin",
    };

    await Http.call("POST", "payment", body).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        props.setdisableTab([
          "step2",
          "step3",
          "step4",
          parseInt(is_Preneed) === 1 ? "" : "step6",
          "step7",
        ]);
        setRedirect(true);
      } else {
        alert(response.message);
      }
    });
    setLoading(false);
  };

  const getPaymentDetaile = async () => {
    const { data, status, message } = await Http.call(
      "POST",
      "packages-services",
      { user_id: user_id, flag: "admin" }
    );
    if (status === 200) {
      setPackageData({ id: data.id, name: data.name, price: data.price });

      setCirtificateCount(parseInt(data.certificate_count));
      setCertificatePrice(parseInt(data.certificate_rate));
      let services = [];
      data.addonCategory.map((item, index) => {
        item.addOns.map((additem, addindex) => {
          if (additem.is_selected) {
            services.push({
              id: additem.id,
              catId: item.id,
              name: additem.service_name,
              price: additem.price,
              type: item.selection_type,
            });
          }
        });
      });
      setServiceData(services);
      setLoading(false);
    } else {
      alert("API ERO+ROR" + message);
    }
  };

  useEffect(() => {
    getPaymentDetaile();
  }, []);

  if (redirect) {
    return (
      <ThankYouView
        setTabval={props.setTabval}
        setdisableTab={props.setdisableTab}
        paymentFlag={"payment"}
      />
    );
  }

  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <Page title="Payment" className={classes.root}>
      <Card>
        <CardHeader title="" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <h2>Enter Payment Detail Below</h2>
              <Typography paragraph>
                Reference site about Lorem Ipsum, giving information on its
                origins, as well as a random Lipsum generator.Reference site
                about Lorem Ipsum, giving information on its origins, as well as
                a random Lipsum generator. Reference site about Lorem Ipsum,
                giving information on its origins, as well as a random Lipsum
                generator.Reference site about Lorem Ipsum, giving information
                on its origins, as well as a random Lipsum generator.
              </Typography>
            </Grid>
            <Formik
              initialValues={{
                holderName: "",
                cardNumber: "",
                expiryDate: "",
                cvc: "",
              }}
              onSubmit={(values) => makePayment(values)}
              validationSchema={validationSchema}
              validate={(e) => validateForm(e, meta)}
            >
              {({
                values,
                errors,
                touched,
                isValid,
                dirty,
                handleBlur,
                handleChange,
                handleSubmit,
                validateForm,
                setErrors,
              }) => {
                return (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <Card>
                          <CardHeader title="Payment Method" />
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                                <div className="Credit-card">
                                  <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={"ture"}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value="ture"
                                      control={<Radio />}
                                      label="Credit Card"
                                    />
                                  </RadioGroup>
                                  <svg {...getCardImageProps({ images })} />
                                  {/* <img src="img/payment1.png" class="pcard" /> */}
                                </div>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Card holder Name"
                                  name="holderName"
                                  value={values.holderName}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.holderName && errors.holderName
                                  )}
                                  helperText={
                                    touched.holderName && errors.holderName
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  inputProps={getCardNumberProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  isInvalid={
                                    (touchedInputs.cardNumber &&
                                      erroredInputs.cardNumber) ||
                                    (touched.cardNumber && errors.cardNumber)
                                  }
                                  fullWidth
                                  label="Card Number"
                                  name="cardNumber"
                                  value={values.cardNumber}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.cardNumber &&
                                      erroredInputs.cardNumber) ||
                                      (touched.cardNumber && errors.cardNumber)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.cardNumber &&
                                        erroredInputs.cardNumber) ||
                                        (touched.cardNumber &&
                                          errors.cardNumber)
                                    )
                                      ? erroredInputs.cardNumber
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  inputProps={getExpiryDateProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  fullWidth
                                  value={values.expiryDate}
                                  name="expiryDate"
                                  label="Expiry Date"
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.expiryDate &&
                                      erroredInputs.expiryDate) ||
                                      (touched.expiryDate && errors.expiryDate)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.expiryDate &&
                                        erroredInputs.expiryDate) ||
                                        (touched.expiryDate &&
                                          errors.expiryDate)
                                    )
                                      ? erroredInputs.expiryDate
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  inputProps={getCVCProps({
                                    onBlur: handleBlur,
                                    onChange: handleChange,
                                  })}
                                  label="CVV Code"
                                  value={values.cvc}
                                  name="cvc"
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  error={Boolean(
                                    (touchedInputs.cvc && erroredInputs.cvc) ||
                                      (touched.cvc && errors.cvc)
                                  )}
                                  helperText={
                                    Boolean(
                                      (touchedInputs.cvc &&
                                        erroredInputs.cvc) ||
                                        (touched.cvc && errors.cvc)
                                    )
                                      ? erroredInputs.cvc
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  fullWidth
                                  label="Zip"
                                  name="Zip"
                                  value={values.Zip}
                                  type="text"
                                  margin="normal"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={Boolean(touched.Zip && errors.Zip)}
                                  helperText={touched.Zip && errors.Zip}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item md={6}>
                        <Card>
                          <CardHeader title="Your Order" />
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                className="order-total"
                              >
                                <ul>
                                  <li>
                                    <p className="total-label">
                                      {packageData.name} Package:
                                    </p>
                                    <p className="total-price">
                                      $
                                      {parseFloat(packageData.price).toFixed(2)}
                                    </p>
                                  </li>
                                  {serviceData.length > 0 &&
                                    serviceData.map((item, index) => {
                                      if (item !== null) {
                                        //total = total + item.price;
                                        return (
                                          <li>
                                            <p className="total-label">
                                              {item.name}:
                                            </p>
                                            <p className="total-price">
                                              + $
                                              {parseFloat(item.price).toFixed(
                                                2
                                              )}
                                            </p>
                                          </li>
                                        );
                                      }
                                    })}
                                  <li>
                                    <p className="total-label">
                                      Death certificates ({cirtificateCount}):
                                    </p>
                                    <p className="total-price">
                                      + $
                                      {parseFloat(
                                        cirtificateCount * certificatePrice -
                                          certificatePrice
                                      ).toFixed(2)}
                                    </p>
                                  </li>

                                  <li>
                                    <p className="total-label">Total:</p>
                                    <p className="total-price">
                                      ${parseFloat(total).toFixed(2)}
                                    </p>
                                  </li>
                                  {Discount !== 0 && Discount < total && (
                                    <li>
                                      <p className="total-label">Discount:</p>
                                      <p className="total-price">
                                        - ${parseFloat(Discount).toFixed(2)}
                                      </p>
                                    </li>
                                  )}
                                  {Discount !== 0 && Discount < total && (
                                    <li>
                                      <p className="total-label">
                                        Payable Amount:
                                      </p>
                                      <p className="total-price">
                                        $
                                        {parseFloat(total - Discount).toFixed(
                                          2
                                        )}
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              </Grid>
                              <Grid
                                container
                                spacing={3}
                                className="discount-box"
                              >
                                <Grid
                                  item
                                  md={12}
                                  style={{
                                    padding: 20,
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      label="Enter Service Discount"
                                      name="promocode"
                                      type="text"
                                      margin="normal"
                                      variant="outlined"
                                      value={PromoCode}
                                      onChange={(e) =>
                                        setPromocode(e.target.value)
                                      }
                                      readOnly={Discount !== 0 ? true : false}
                                    />
                                    {resStatus === 200 && (
                                      <div style={{ color: "green" }}>
                                        {resMessage}
                                      </div>
                                    )}
                                    {resStatus !== 200 &&
                                      resStatus !== null && (
                                        <div style={{ color: "red" }}>
                                          {resMessage}
                                        </div>
                                      )}
                                  </Box>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    type="button"
                                    onClick={() => CheckCode()}
                                    disabled={promoLoader}
                                    style={{
                                      height: "100%",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {promoLoader
                                      ? "Checking"
                                      : Discount !== 0
                                      ? "Remove"
                                      : "Check"}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Box display="flex" justifyContent="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={isAgree}
                                onChange={handleChangeChk}
                                color="primary"
                              />
                            }
                            label={
                              <>
                                I agree to Fernwood{" "}
                                <span className="purple">
                                  {" "}
                                  <a className="gold paymentlink" href="https://www.fernwood.com/terms-of-service">Terms and conditions</a>
                                </span>{" "}
                                and{" "}
                                <span className="purple">
                                <a className="gold paymentlink" href="https://www.fernwood.com/privacy-policy">Privacy policy </a>
                                </span>{" "}
                              </>
                            }
                          />
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={2}
                          pr={2}
                          pl={2}
                          pb={2}
                        >
                          <Button
                            type="text"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              if (parseInt(is_Preneed) === 1) {
                                props.setTabval("step6");
                              } else {
                                props.setTabval("step3");
                              }
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            //onClick={() => makePayment()}
                            disabled={!isValid || !isAgree || !dirty}
                          >
                            Complete Purchase
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  crtPrice: state.package.certificatePrice,
  categoryData: state.package.categoryData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentView);
