import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// Layouts : importing layout files
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";

// Account/AccountView :
import AccountView from "src/views/Account/AccountView";
import ChangPasswordView from "src/views/Account/AccountView/ChangePasswordView";

// Roles :
import RoleListView from "src/views/Roles/RoleListView";
import AddRoleView from "src/views/Roles/AddRoleView";
import EditRoleView from "src/views/Roles/EditRoleView";

// User :
import UsersTabView from "src/views/User/UsersTabView";
import AddUserView from "src/views/User/AddUserView";
import EditUserView from "src/views/User/EditUserView";
import AddFrontUserView from "src/views/User/AddFrontUserView";
import EditFrontUserView from "src/views/User/EditFrontUserView";

// Contacts :
import ContactsListView from "./views/Contacts/ContactsListView";
import EditContactView from "src/views/Contacts/EditContactView";

// Emailtemplate :
import EmailTemplateListView from "src/views/Emailtemplate/EmailTemplateListView";
import EditEmailtemplate from "src/views/Emailtemplate/EmailTemplateListView/EditEmailtemplate";

// Dashboard :
import DashboardView from "src/views/Dashboard/DashboardView";

// Auth :
import LoginView from "src/views/Auth/LoginView";
import ForgotPasswordView from "src/views/Auth/ForgotPasswordView";
import ResetPasswordView from "src/views/Auth/ResetPasswordView";
import LogoutView from "src/views/Auth/LogoutView";

// Errors :
import NotFoundView from "src/views/Errors/NotFoundView";

// Setting :
import SettingsView from "src/views/Settings/SettingsView";

// Discount :
import DiscountView from "./views/Discount/DiscountView";
import AddDiscountView from "./views/Discount/AddDiscountView/index.js";
import EditDiscountView from "./views/Discount/EditDiscountView";

// PriceList :
import PriceView from "./views/PriceList/PriceView";
import AddPriceView from "./views/PriceList/AddPriceView/index.js";
import EditPriceView from "./views/PriceList/EditPriceView";

// Review :
import ReviewListView from "src/views/Reviews/ReviewListView";
import AddReview from "src/views/Reviews/AddReview";
import EditReview from "src/views/Reviews/EditReview";

// Subscription :
import SubscriptionListView from "src/views/Subscriptions/SubscriptionListView";
import SubscriptionDetailsView from "src/views/Subscriptions/SubscriptionDetailsView";

// Services :
import ServicesView from "./views/Services/ServicesView";
import AddServicesView from "./views/Services/AddServicesView";
import EditServicesView from "./views/Services/EditServicesView";

// Packages :
import PackagesView from "./views/Packages/PackagesView";
import AddPackageView from "./views/Packages/AddPackageView";
import EditPackageView from "./views/Packages/EditPackageView";

// Category :
import CategoryListView from "./views/Category/CategoryListView";
import AddCategoryView from "./views/Category/AddCategoryView";
import EditCategoryView from "./views/Category/EditCategoryView";

// Sections :
import SectionTabView from "src/views/Sections/SectionTabView";
import AddSectionView from "./views/Sections/AddSectionView";
import EditSectionView from "./views/Sections/EditSectionView";

// Banners :
import BannersView from "./views/Banners/BannersView";
import AddBannerView from "./views/Banners/AddBannerView";
import EditBannerView from "./views/Banners/EditBannerView";

// Questionnaire :
import AddQuestionnaireView from "./views/Questionnaire/AddQuestionnaireView";
import EditQuestionnaireView from "./views/Questionnaire/EditQuestionnaireView";
import QuestionnaireTabView from "src/views/Questionnaire/QuestionnaireTabView";

// counsellor :
import ListCounsellorView from "./views/Counsellor/ListCounsellorView";
import EditCounsellorView from "./views/Counsellor/EditCounsellorView";
import ThankYouView from "src/views/Counsellor/AddApplicationView/PaymentView/ThankYouView";
import ShowStatusLog from "src/views/Counsellor/ListCounsellorView/ShowStatusLog";
import CustomAddApplicationView from "./views/Counsellor/AddApplicationView/V1";

// Status :
import StatusListView from "./views/Status/StatusListView";
import AddStatusView from "./views/Status/AddStatusView";
import EditStatusView from "./views/Status/EditStatusView";

// clients :
import ClientsListView from "src/views/Clients/index";
import EditClientView from "src/views/Clients/EditClientView/index";

// DocumentForm :
import DocumentFormListView from "./views/DocumentForm/DocumentFormListView";
import AddDocumentFormView from "./views/DocumentForm/AddDocumentFormView";
import EditDocumentFormView from "./views/DocumentForm/EditDocumentFormView";

//ActivityLogs
import ActivityLogs from "./views/ActivityLogs/ActivityLogs";

//Manual Action
import ManualAction from "src/views/Counsellor/ListCounsellorView/ManualAction";

// Company :
import CompaniesView from "./views/Companies/CompaniesView";
import AddCompanyView from "./views/Companies/AddCompanyView";
import EditCompanyView from "./views/Companies/EditCompanyView";

// Designation :
import DesignationView from "./views/Designation/DesignationView";
import AddDesignationView from "./views/Designation/AddDesignationView";
import EditDesignationView from "./views/Designation/EditDesignationView";

const routes = (loggedIn) => {
  const routes = [
    {
      path: "app",
      element: loggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: "account", element: <AccountView /> },
        { path: "change_password", element: <ChangPasswordView /> },
        { path: "logout", element: <LogoutView /> },
        { path: "roles", exact: true, element: <RoleListView /> },
        { path: "roles/add", exact: true, element: <AddRoleView /> },
        { path: "roles/:id", exact: true, element: <EditRoleView /> },
        { path: "users", exact: true, element: <UsersTabView /> },
        { path: "users/add", exact: true, element: <AddUserView /> },
        { path: "users/:id", exact: true, element: <EditUserView /> },
        { path: "front/user/add", exact: true, element: <AddFrontUserView /> },
        { path: "front/user/:id", exact: true, element: <EditFrontUserView /> },
        {
          path: "contacts",
          exact: true,
          element: <Outlet />,
          children: [
            { path: "", element: <ContactsListView /> },
            { path: "add", element: <EditContactView /> },
            { path: ":id", element: <EditContactView /> },
          ],
        },
        {
          path: "activation-tokens",
          exact: true,
          element: <Outlet />,
          children: [
            { path: "", element: <ClientsListView /> },
            { path: "add", element: <EditClientView /> },
            { path: ":id", element: <EditClientView /> },
          ],
        },
        { path: "categories", exact: true, element: <CategoryListView /> },
        { path: "categories/add", exact: true, element: <AddCategoryView /> },
        { path: "categories/:id", exact: true, element: <EditCategoryView /> },
        { path: "sections", exact: true, element: <SectionTabView /> },
        { path: "section/add", exact: true, element: <AddSectionView /> },
        { path: "section/:id", exact: true, element: <EditSectionView /> },
        { path: "counselors", exact: true, element: <ListCounsellorView /> },
        {
          path: "counselor/:id",
          exact: true,
          element: <EditCounsellorView />,
        },
        {
          path: "counselor/:id/manual-action/",
          exact: true,
          element: <ManualAction />,
        },
        // {
        //   path: "counselors/application/add",
        //   exact: true,
        //   element: <AddApplicationView />,
        // },
        {
          path: "application/add",
          exact: true,
          //element: <AddApplicationView />,
          element: <CustomAddApplicationView />,
        },
        // {
        //   path: "v1/application/add",
        //   exact: true,
        //   element: <CustomAddApplicationView />,
        // },
        {
          path: "application/edit/:id",
          exact: true,
          //element: <AddApplicationView />,
          element: <CustomAddApplicationView />,
        },
        // {
        //   path: "v1/application/edit/:id",
        //   exact: true,
        //   element: <CustomAddApplicationView />,
        // },
        {
          path: "statuslog/show/:id",
          exact: true,
          element: <ShowStatusLog />,
        },

        {
          path: "discount",
          exact: true,
          element: <DiscountView />,
        },
        {
          path: "discount/add",
          exact: true,
          element: <AddDiscountView />,
        },
        {
          path: "discount/:id",
          exact: true,
          element: <EditDiscountView />,
        },

        {
          path: "designation",
          exact: true,
          element: <DesignationView />,
        },
        {
          path: "designation/add",
          exact: true,
          element: <AddDesignationView />,
        },
        {
          path: "designation/:id",
          exact: true,
          element: <EditDesignationView />,
        },

        {
          path: "price",
          exact: true,
          element: <PriceView />,
        },
        {
          path: "price/add",
          exact: true,
          element: <AddPriceView />,
        },
        {
          path: "price/:id",
          exact: true,
          element: <EditPriceView />,
        },

        {
          path: "addons",
          exact: true,
          element: <ServicesView />,
        },
        {
          path: "addons/add",
          exact: true,
          element: <AddServicesView />,
        },
        {
          path: "addons/:id",
          exact: true,
          element: <EditServicesView />,
        },
        {
          path: "services",
          exact: true,
          element: <PackagesView />,
        },
        {
          path: "services/add",
          exact: true,
          element: <AddPackageView />,
        },
        {
          path: "services/:id",
          exact: true,
          element: <EditPackageView />,
        },
        {
          path: "emailtemplate",
          exact: true,
          element: <EmailTemplateListView />,
        },
        {
          path: "editemailtemplate/:id",
          exact: true,
          element: <EditEmailtemplate />,
        },
        {
          path: "emailtemplate/manual",
          exact: true,
          element: <EmailTemplateListView />,
        },
        {
          path: "emailtemplate/manual/add",
          exact: true,
          element: <EditEmailtemplate />,
        },
        {
          path: "emailtemplate/manual/:id",
          exact: true,
          element: <EditEmailtemplate />,
        },
        { path: "dashboard", element: <DashboardView /> },
        { path: "settings", element: <SettingsView /> },
        { path: "reviews", exact: true, element: <ReviewListView /> },
        {
          path: "review/add",
          exact: true,
          element: <AddReview />,
        },
        {
          path: "review/:id",
          exact: true,
          element: <EditReview />,
        },
        {
          path: "subscriptions",
          exact: true,
          element: <SubscriptionListView />,
        },
        {
          path: "subscriptions/:id",
          exact: true,
          element: <SubscriptionDetailsView />,
        },
        {
          path: "banners",
          exact: true,
          element: <BannersView />,
        },
        {
          path: "banners/add",
          exact: true,
          element: <AddBannerView />,
        },
        {
          path: "banners/:id",
          exact: true,
          element: <EditBannerView />,
        },
        {
          path: "questionnaire",
          exact: true,
          element: <QuestionnaireTabView />,
        },
        {
          path: "questionnaire/add",
          exact: true,
          element: <AddQuestionnaireView />,
        },
        {
          path: "questionnaire/:id",
          exact: true,
          element: <EditQuestionnaireView />,
        },

        { path: "thank-you", element: <ThankYouView /> },

        {
          path: "status",
          exact: true,
          element: <StatusListView />,
        },
        {
          path: "status/add",
          exact: true,
          element: <AddStatusView />,
        },
        {
          path: "status/:id",
          exact: true,
          element: <EditStatusView />,
        },

        {
          path: "document-form",
          exact: true,
          element: <DocumentFormListView />,
        },
        {
          path: "document-form/add",
          exact: true,
          element: <AddDocumentFormView />,
        },
        {
          path: "document-form/:id",
          exact: true,
          element: <EditDocumentFormView />,
        },
        {
          path: "activity-log",
          exact: true,
          element: <ActivityLogs />,
        },

        {
          path: "companies",
          exact: true,
          element: <Outlet />,
          children: [
            { path: "", element: <CompaniesView /> },
            { path: "add", element: <AddCompanyView /> },
            { path: ":id", element: <EditCompanyView /> },
          ],
        },

        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/",
      element: !loggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
      children: [
        { path: "/", element: <Navigate to="/app/dashboard" /> },
        { path: "login", element: <LoginView /> },
        { path: "forgotpassword", element: <ForgotPasswordView /> },
        { path: "reset-password", element: <ResetPasswordView /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "404", element: <NotFoundView /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ];
  return routes;
};

export default routes;
