import React from "react";
import { useField } from "formik";
import { Grid, TextField } from "@material-ui/core";
import { usePlacesWidget } from "react-google-autocomplete";

const GoogleAddressBox = ({ address, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const setAddressData = ({
    address_components,
    geometry,
    formatted_address,
    name,
  }) => {
    let addressComponent = {};
    if(name) {
      addressComponent.name = name;
    }
    if (address_components.length > 0) {
      addressComponent.address_line_1 = formatted_address;
      addressComponent.locationAddress = formatted_address;
      addressComponent.lat = geometry.location.lat();
      addressComponent.long = geometry.location.lng();
      // if (address_components.length > 0) {
      address_components.map((address_component) => {
        if (address_component.types.includes("administrative_area_level_1")) {
          addressComponent.state = address_component.long_name;
        } else if (address_component.types.includes("locality")) {
          addressComponent.city = address_component.long_name;
        } else if (address_component.types.includes("postal_code")) {
          addressComponent.zip = address_component.long_name;
        } else if (address_component.types.includes("country")) {
          addressComponent.country = address_component.long_name;
        }
      });
    }

    return addressComponent;
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw",
    options: {
      types: ["geocode", "establishment"],
      fields: ["ALL"],
    },
    onPlaceSelected: (place) => {
      props.setAddress(setAddressData(place));
      if (place.business_status === "OPERATIONAL") {
        setValue(place.name + ", " + place.formatted_address);
      } else {
        setValue(place.formatted_address);
      }
    },
  });

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {/* <TextBoxField name="location_name" label="Location Name"/> */}
      <TextField
        fullWidth
        variant="outlined"
        inputRef={ref}
        placeholder={props.placeholder}
        multiline
        autoComplete="off"
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        {...field}
        {...props}
      />
    </Grid>
  );
};

export default GoogleAddressBox;
