// EmailMessageField.js
import React, { memo } from "react";
import { Grid, Box, CardHeader, Typography } from "@material-ui/core";
import { CKEditor } from "ckeditor4-react";

const EmailMessageField = memo(
  ({ emailMessage, setEmailMessage, touched, errors ,values}) => {
    console.log("emailMessage--->>",emailMessage)
    console.log("VALUE--->>",values)
    return (
      <Grid item xs={12}>
        <Box>
          <CardHeader title="Message" />
          <CKEditor
            name="emailMessage"
            key={emailMessage}
            initData={emailMessage}
            onBlur={(event) => {
              setEmailMessage("emailMessage", event.editor.getData()); // Update message using setFieldValue
            }}
            onChange={(event) => {
              setEmailMessage("emailMessage", event.editor.getData()); // Update message using setFieldValue
            }}
            config={{
              extraPlugins: "docprops",
              removePlugins: "about,image,easyimage",
              fullPage: false,
              allowedContent: true,
              formatTags: "p;h1;h2;h3;pre;span;",
              height: 320,
            }}
          />
          {touched.emailMessage && errors.emailMessage && (
            <Typography variant="body2" color="error">
              {errors.emailMessage}
            </Typography>
          )}
        </Box>
      </Grid>
    );
  }
);

export default EmailMessageField;
