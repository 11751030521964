import React, { useState } from "react";
import PostquestBox from "../../components/PostquestBox/index";
import { getSchema } from "../../utils/Validation";
import { Formik } from "formik";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { yellow } from "@mui/material/colors";
const theme = createTheme({
  palette: {
    custom: {
      main: "#D8B34F",
      contrastText: "#f8f9fa",
    },
    error: { main: yellow[500] },
  },
  components: {
    MuiTextField: { styleOverrides: { root: { color: "red" } } },
  },
  typography: {
    button: {
      fontSize: "1rem",
      textTransform: "none",
    },
  },
});

export default function LayoutTwo({ data, loading, onSubmitForm }) {
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  const [Address, setAddress] = useState(
    data.question.address !== undefined ? data.question.address : {}
  );
  let InitialValues;
  if (
    data.question !== undefined &&
    data.question.name_fields !== undefined &&
    data.question.control === 14
  ) {
    InitialValues = {
      firstname:
        data.question.name_fields.first_name !== undefined
          ? data.question.name_fields.first_name
          : "",
      middlename:
        data.question.name_fields.middle_name !== undefined
          ? data.question.name_fields.middle_name
          : "",
      lastname:
        data.question.name_fields.last_name !== undefined
          ? data.question.name_fields.last_name
          : "",
    };
  } else if (
    data.question !== undefined &&
    data.question.control === 12 &&
    data.question.address !== undefined
  ) {
    InitialValues = {
      answer:
        data.question.address.address_line_1 !== undefined
          ? data.question.address.address_line_1
          : null,
    };
  } else if (
    data.question !== undefined &&
    data.question.control === 16 &&
    data.question.address !== undefined
  ) {
    InitialValues = {
      house_name:
        data.question.address.house_name !== undefined
          ? data.question.address.house_name
          : null,
      answer:
        data.question.address.address_line_1 !== undefined
          ? data.question.address.address_line_1
          : null,
      address_line_1:
        data.question.address.address_line_1 !== undefined
          ? data.question.address.address_line_1
          : null,
      city:
        data.question.address.city !== undefined
          ? data.question.address.city
          : null,
      country:
        data.question.address.country !== undefined
          ? data.question.address.country
          : null,
      zip:
        data.question.address.zip !== undefined
          ? data.question.address.zip
          : null,
      state:
        data.question.address.state !== undefined
          ? data.question.address.state
          : null,
      unit_type:
        data.question.address.unit_type !== undefined
          ? data.question.address.unit_type
          : null,
      unit_number:
        data.question.address.unit_number !== undefined
          ? data.question.address.unit_number
          : null,
    };
  } else if (data.question !== undefined && data.question.control === 3) {
    InitialValues = {
      answer: data.question
        ? data.question.default_value.length > 1
          ? data.question.default_value.split(",").map((x) => parseInt(x))
          : data.question.default_value !== ""
          ? [data.question.default_value]
          : []
        : [],
    };
  } else  if (data.question !== undefined && data.question.control === 10) { 
    InitialValues = {
      answer: data.question ? data.question.default_value.toString() : "",
    };
  } else {
    InitialValues = {
      answer: data.question ? data.question.default_value : "",
    };
  }

  return (
    <Formik
      initialValues={InitialValues}
      validationSchema={
        data.question.question_id === 13
          ? getSchema(data.question.control, data.question.question_id)
          : getSchema(data.question.control)
      }
      onSubmit={(values, actions) => {
        if (data.question.control === 3) {
          let custValue = { answer: values.answer.toString() };
          onSubmitForm(custValue, actions, true);
        } else if (data.question.control === 10) {
          if (checkPhoneNumber !== 1) {
            onSubmitForm(values, actions, true);
          }
        } else if (data.question.control === 12) {
          if (Object.keys(Address).length === 0) {
            actions.setErrors({
              answer: "Please select any place from google address dropdown",
            });
          } else {
            let custValue = { address: Address, answer: values.answer };
            onSubmitForm(custValue, actions, true);
          }
        } else if (data.question.control === 16) {
          let fullAddAnswer = values.answer;
          let fullAddress = values;
          delete fullAddress.answer;
          fullAddress.long = Address.long;
          fullAddress.lat = Address.lat;
          let custValue = { address: fullAddress, answer: fullAddAnswer };
          onSubmitForm(custValue, actions, true);
        } else if (data.question.control === 14) {
          let custValue = {
            address: {
              first_name: values.firstname,
              middle_name: values.middlename,
              last_name: values.lastname,
            },
            answer:
              values.firstname +
              "," +
              values.middlename +
              "," +
              values.lastname,
          };
          onSubmitForm(custValue, actions, true);
        } else {
          //console.log(values,"Values...........");
          //return false;
          onSubmitForm(values, actions, true);
        }
      }}
    >
      {({ handleSubmit, submitForm, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ThemeProvider theme={theme}>
              <PostquestBox
                question={data.question}
                onHandleSubmit={submitForm}
                manualSubmit={onSubmitForm}
                setCheckPhoneNumber={setCheckPhoneNumber}
                setAddress={setAddress}
                setFieldValue={setFieldValue}
              />
            </ThemeProvider>
          </form>
        );
      }}
    </Formik>
  );
}
