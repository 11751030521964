import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon,
  User as UserIcon,
  Mail as MailIcon,
  Percent as PercentIcon,
  Truck as TruckIcon,
  MessageCircle as MessageCircleIcon,
  HelpCircle as HelpCircleIcon,
  Image as ImageIcon,
  FileText as FileTextIcon,
  File as FileIcon,
  List as ListIcon,
  AlignJustify as CategoryIcon,
  Airplay as AirplayIcon,
  CheckCircle as CheckCircleIcon,
  Grid as GridIcon,
  Monitor as MonitorIcon,
  Home as CompaniesIcon,
  Briefcase as DesignationIcon,
  PhoneCall as ContactsIcon,
  Server as ServerIcon,
} from "react-feather";

export const getlowerpath = (str) => str.toLowerCase();
const getTabs = (path, authuser) => {
  let Master = [
    {
      id: 1,
      href: "/app/users",
      icon: UsersIcon,
      title: "Users",
    },
    {
      id: 2,
      href: "/app/roles",
      icon: ListIcon,
      title: "Roles",
    },
    {
      id: 3,
      href: "/app/services",
      icon: ShoppingBagIcon,
      title: "Services",
    },
    {
      id: 4,
      href: "/app/categories",
      icon: CategoryIcon,
      title: "Add-ons Categories",
    },
    {
      id: 5,
      href: "/app/addons",
      icon: TruckIcon,
      title: "Add-ons",
    },

    {
      id: 6,
      href: "/app/sections",
      icon: GridIcon,
      title: "Sections",
    },
    {
      id: 7,
      href: "/app/status",
      icon: CheckCircleIcon,
      title: "Application Status",
    },
    {
      id: 8,
      href: "/app/activation-tokens",
      icon: MonitorIcon,
      title: "Activation Tokens",
    },
  ];

  let siteSetting = [
    {
      id: 1,
      href: "/app/settings",
      icon: SettingsIcon,
      title: "Settings",
    },
    {
      id: 2,
      href: "/app/banners",
      icon: ImageIcon,
      title: "Banners",
    },
    {
      id: 3,
      href: "/app/Price",
      icon: FileIcon,
      title: "Price List",
    },
    {
      id: 4,
      href: "/app/emailtemplate",
      icon: MailIcon,
      title: "System Email Templates",
    },
    {
      id: 5,
      href: "/app/emailtemplate/manual",
      icon: MailIcon,
      title: "Custom Email Templates",
    },
    {
      id: 6,
      href: "/app/activity-log",
      icon: FileTextIcon,
      title: "Activity Logs",
    },
  ];

  let Contacts = [
    {
      id: 1,
      href: "/app/contacts",
      icon: ContactsIcon,
      title: "Contacts",
    },
    {
      id: 2,
      href: "/app/companies",
      icon: CompaniesIcon,
      title: "Companies",
    },
    {
      id: 3,
      href: "/app/designation",
      icon: DesignationIcon,
      title: "Staff Positions",
    },
  ];

  let MainTabs = [
    {
      id: 1,
      href: "/app/dashboard",
      icon: BarChartIcon,
      title: "Dashboard",
    },
    {
      id: 2,
      icon: ListIcon,
      title: "Masters",
      children: Master,
      isActive: Master.some((tab) => getlowerpath(tab["href"]) === path),
    },
    {
      id: 3,
      icon: ServerIcon,
      title: "Contacts",
      children: Contacts,
      isActive: Contacts.some((tab) => getlowerpath(tab["href"]) === path),
    },
    {
      id: 4,
      href: "/app/counselors",
      icon: AirplayIcon,
      title: "Applications",
    },
    {
      id: 5,
      href: "/app/questionnaire",
      icon: HelpCircleIcon,
      title: "Questionnaire",
    },
    {
      id: 6,
      href: "/app/document-form",
      icon: FileTextIcon,
      title: "Document Forms",
    },
    {
      id: 7,
      href: "/app/discount",
      icon: PercentIcon,
      title: "Service Discount",
    },
    {
      id: 8,
      href: "/app/subscriptions",
      icon: UsersIcon,
      title: "Payment Records",
    },
    {
      id: 9,
      icon: SettingsIcon,
      title: "Site Settings",
      children: siteSetting,
      isActive: siteSetting.some((tab) => getlowerpath(tab["href"]) === path),
    },
    {
      id: 10,
      href: "/app/reviews",
      icon: MessageCircleIcon,
      title: "Reviews",
    },
  ];

  let MenuTabs = [];
  let newArr = [];
  let newChildMastArr = [];
  let newChildSiteSetArr = [];
  let newChildContactsArr = [];
  let MenuArr = [];

  function extractArrays(arr, acc = []) {
    if (arr.length === 0) return acc;
    let pure = arr.filter((elm) =>
      elm.children ? !Array.isArray(elm.children) : !Array.isArray(elm)
    );
    if (pure.length > 0) {
      acc.push(pure);
    }

    acc.concat(
      arr
        .filter((elm) => Array.isArray(elm.children ? elm.children : elm))
        .map((elm) => extractArrays(elm.children ? elm.children : elm, acc))
    );

    return acc;
  }

  MenuTabs = extractArrays(MainTabs);
  //console.log(MenuTabs, "MenuTabs");
  MenuTabs.map((menuitem, index) => {
    // console.log(item, "in menu tabs");
    menuitem.map((submenuitem, subindex) => {
      MenuArr.push(submenuitem);
    });
  });

  //let k = 0;
  let isMaster = false;
  let isSetting = false;
  let isContact = false;

  // console.log(MenuArr, "sdlkfjldfkj");

  authuser.permission.map((item, index) => {
    MenuArr.map((tab, key) => {
      if ([tab.title].includes(item.title)) {
        if (item.view === 1) {
          if (Master.find(({ title }) => title === item.title)) {
            newChildMastArr.push({
              id: tab.id,
              href: tab.href,
              icon: tab.icon,
              title: tab.title,
            });
            if (!isMaster) {
              if (newChildMastArr.length > 0) {
                newArr.push({
                  id: 2,
                  icon: ListIcon,
                  title: "Masters",
                  children: newChildMastArr,
                  isActive: newChildMastArr.some(
                    (mtab) => getlowerpath(mtab["href"]) === path
                  ),
                });
                isMaster = true;
              }
            }
          } else if (Contacts.find(({ title }) => title === item.title)) {
            newChildContactsArr.push({
              id: tab.id,
              href: tab.href,
              icon: tab.icon,
              title: tab.title,
            });
            if (!isContact) {
              if (newChildContactsArr.length > 0) {
                newArr.push({
                  id: 3,
                  icon: ContactsIcon,
                  title: "Contacts",
                  children: newChildContactsArr,
                  isActive: newChildContactsArr.some(
                    (stab) => getlowerpath(stab["href"]) === path
                  ),
                });
                isContact = true;
              }
            }
          } else if (siteSetting.find(({ title }) => title === item.title)) {
            newChildSiteSetArr.push({
              id: tab.id,
              href: tab.href,
              icon: tab.icon,
              title: tab.title,
            });
            if (!isSetting) {
              if (newChildSiteSetArr.length > 0) {
                newArr.push({
                  id: 9,
                  icon: SettingsIcon,
                  title: "Site Settings",
                  children: newChildSiteSetArr,
                  isActive: newChildSiteSetArr.some(
                    (stab) => getlowerpath(stab["href"]) === path
                  ),
                });
                isSetting = true;
              }
            }
          } else {
            newArr.push({
              id: tab.id,
              href: tab.href,
              icon: tab.icon,
              title: tab.title,
            });
          }
        }
        // k++;
      }
    });
  });

  // console.log(newArr, "skjdfhskfdhsf");

  return newArr;
};
export default getTabs;
