import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Card, makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Page from "src/components/Page";
import ReviewFormDetails from "../Components/ReviewFormDetails";
import Toolbar from "../Components/Toolbar";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Review", url: "/app/reviews" },
  { name: "Edit Review", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const EditReview = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    user_id: "",
    star: "",
    remark: "",
  });

  const [userList, setUserList] = useState([]);

  const fetchUserList = async () => {
    try {
      const response = await Http.call("GET", "admin/front_user");
      setUserList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Http.call("GET", "admin/review/" + id).then(async (response) => {
        const { data } = response;
        setValues({
          id: id,
          user_id: data.user_id,
          star: data.star,
          remark: data.remark,
        });

        fetchUserList();
        setLoading(false);
      });
    };

    fetchData();
  }, [id]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  if (Loading) {
    return (
      <Card style={{ height: 300 }}>
        <LinearProgress />
      </Card>
    );
  }
  return (
    <Page className={classes.root} title="Add Review">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar mode={"Add"} />
        <Grid container spacing={3} className={classes.QuesDetail}>
          <Grid item lg={12} md={12} xs={12}>
            <ReviewFormDetails
              formValues={values}
              setvalues={setValues}
              userlists={userList}
              handleChange={handleChange}
              mode={"Edit"}
              setSnackbar={props.setSnackbar}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditReview);
