import { Button, Grid } from "@mui/material";
import React from "react";
import RadioBox from "../QuestionTemplates/RadioBox";
import TextBoxField from "../QuestionTemplates/TextBoxField";
import AutoCompleteBox from "../QuestionTemplates/AutoCompleteBox";
import DatePickerBox from "../QuestionTemplates/DatePickerBox";
import { useTheme } from "@mui/styles";
import NameBox from "../QuestionTemplates/NameBox";

export default function PreQuestBox({
  question,
  onSubmitForm,
  disabled,
  isIframe,
  values,
}) {
  const theme = useTheme();
  // console.log(theme);
  if (isIframe && question?.is_last === 1 && question?.default_value !== "") {
    return (
      <Grid
        container
        // justifyContent="space-between"
        direction="column"
        alignItems="center"
      >
        <Grid item style={{ margin: 20 }}>
          Your pre-questionnaire has been completed.
        </Grid>
        <Grid item md={2}>
          <Button
            onClick={() => {
              try {
                window.open("/register", "_blank");
              } catch (e) {
                alert(e);
              }
            }}
            variant="custom"
            fullWidth={false}
          >
            Please Register
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" direction="column">
      <Grid item xs={12} md={12} style={{ maxWidth: "100%" }}>
        <div
          className="que-title"
          style={{
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
          dangerouslySetInnerHTML={{ __html: question.question }}
        ></div>
        {question.question_description !== undefined &&
          question.question_description !== null &&
          question.question_description !== "" && (
            <div
              className="que-title"
              style={{
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                fontSize: "12px",
                fontWeight: "normal",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  "<strong>Note:</strong>" + question.question_description,
              }}
            ></div>
          )}
      </Grid>
      <Grid item xs={12} md={12} style={{ maxWidth: "100%" }}>
        <hr />
      </Grid>
      <Grid item xs={12} md={12} style={{ maxWidth: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignContent="center"
          style={{ height: 250, overflow: "auto", padding: 0 }}
        >
          <Grid item>
            {[1].includes(question.control) && (
              <TextBoxField
                name="answer"
                label={question.placeholder}
                disabled={disabled}
                style={{ width: "100%" }}
              />
            )}
            {question.control === 2 && (
              <RadioBox
                name="answer"
                options={question.options}
                label={question.placeholder}
                disabled={disabled}
                onSubmitForm={onSubmitForm}
              />
            )}
            {question.control === 4 &&
              [1, 5, 6].includes(question.control_handler) && (
                <AutoCompleteBox
                  name="answer"
                  options={question.options}
                  label={question.placeholder}
                  textFieldProps={{
                    fullWidth: true,
                    margin: "normal",
                    variant: "standard",
                    disabled: disabled,
                  }}
                />
              )}
            {question.control === 8 && (
              <DatePickerBox
                name="answer"
                label={question.placeholder}
                disabled={disabled}
              />
            )}
            {question.control === 13 && (
              <DatePickerBox
                name="answer"
                label={question.placeholder}
                maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                disabled={disabled}
              />
            )}
            {question.control === 14 && <NameBox />}
          </Grid>
          {isIframe && (
            <Grid item>
              {[8, 13, 1, 4, 2].includes(question.control) && (
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignContent="center"
                >
                  <Button
                    variant="outlined"
                    color="custom"
                    onClick={() => onSubmitForm(values, {}, false)}
                    disabled={question.is_previous === 1 ? true : false}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="outlined"
                    color="custom"
                    onClick={() => onSubmitForm()}
                  >
                    Next
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
