import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  InputLabel,
  makeStyles,
  NativeSelect,
  DialogTitle,
  FormControl,
  Dialog,
  DialogContentText,
  DialogContent,
  MenuItem,
  FormControlLabel,
  Select,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import UpdateCounsellorStatus from "./UpdateCounsellorStatus";
import { Edit, Info, Download, Eye } from "react-feather";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import PersonAddDisabledOutlinedIcon from "@material-ui/icons/PersonAddDisabledOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import moment from "moment";
import { chkRoutePerm } from "src/services/Helper";
import * as appConst from "src/constants";

export const selectAppIdData = (value) => {
  return value;
};
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  
  
}));

const Results = ({
  className,
  counsellors,
  Counsellor,
  pageLoading,
  fetchCounsellor,
  deleteCounsellors,
  counsellorsDataArr,
  fetchCounsellorsData,
  counsellorUpdate,
  selectedIds,
  setSelectedIds,
  counsellorUnassign,
  userData,
  currntTabsValue,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);
  const [appId, setAppId] = useState(0);
  const [appUserEmail, setAppUserEmail] = useState("");
  const [showCounsModel, setShowCounsModel] = useState(false);
  const [appCounsStatus, setAppCounsStatus] = useState({
    user_name: "",
    couns_id: "",
    app_id: "",
    status: [],
  });
  const [applicationType, setApplicationType] = useState("All");
  const [filteredData, setFilteredData] = useState(counsellors);

  const handleClickOpen = (event, userEmail) => {
    setAppUserEmail(userEmail);
    setAppId(event.currentTarget.value);
    setOpen(true);
  };
  const chkEditRoutPerm = chkRoutePerm("/app/counselors/edit", userData);
  const chkDelRoutPerm = chkRoutePerm("/app/counselors/delete", userData);

  const handleClickUnassign = (event, index, value) => {
    const appidval = event.currentTarget.value;
    const data = { app_id: appidval };
    counsellorUnassign(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const [anchorEl, setAnchorEl] = useState(null);
  // const actionopen = Boolean(anchorEl);

  // const handleActionClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleActionClose = () => {
  //   setAnchorEl(null);
  // };

  const handleChange = async (event, index, value) => {
    let counsellor_id = event.target.value;
    const data = { couns_id: counsellor_id, app_id: appId };
    counsellorUpdate(data);
    setOpen(false);
  };

  const handleApplicationTypeChange = (event) => {
    const selectedValue = event.target.value;
    setApplicationType(selectedValue);

    // Filter data based on the selected value
    const newData =
      selectedValue === "All"
        ? counsellors // Show all data when "All" is selected
        : counsellors.filter(
            (counsellor) => counsellor.application_type === selectedValue
          );

    setFilteredData(newData.length > 0 ? newData : []);
  };

  const classes = useStyles();
  const responsive = "standard";
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    rowsPerPageOptions: [],
    tableBodyMaxHeight: "740px",
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      let idsTo = rowsSelected.map((d) => counsellors[d].app_id);
      setSelectedIds(idsTo);
    },
    rowsPerPage: 100,
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Counsellor.csv" },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
    customToolbar: () => {
      return (
        <FormControlLabel
          control={
            <Select
              value={applicationType}
              onChange={handleApplicationTypeChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Pre-Need">Pre-Need</MenuItem>
              <MenuItem value="At-Need">At-Need</MenuItem>
            </Select>
          }
          label="Application-Type : "
          labelPlacement="start"
        />
      );
    },
  };

  const columns = [
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        // display: chkEditRoutPerm ? true : false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => (
          <div className="ta-flex actionIcon">
            {/* {chkEditRoutPerm ? (
              <Tooltip disableFocusListener title="Edit Application">
                <Button
                  size="small"
                  color="primary"
                  component={RouterLink}
                  //  to={"/app/application/edit/" + counsellors[dataIndex].user_id} //existing ,app_id use only histroy data show peropse
                  to={"/app/application/edit/" + counsellors[dataIndex].app_id}
                >
                  <Edit />
                </Button>
              </Tooltip>
            ) : (
              ""`
            )} */}

            {chkEditRoutPerm ? (
              <Tooltip disableFocusListener title="Edit Application">
                <Button
                  size="small"
                  color="primary"
                  component={RouterLink}
                  to={"/app/application/edit/" + counsellors[dataIndex].user_id} //existing ,app_id use only histroy data show peropse
                  // to={"/app/application/edit/" + counsellors[dataIndex].app_id} //use app.id
                >
                  <Eye />
                </Button>
              </Tooltip>
            ) : (
              ""
            )}

            {/* {chkEditRoutPerm ? (
              <Tooltip disableFocusListener title="Application Status">
                <Button
                  color="primary"
                  size="small"
                  component={RouterLink}
                  to={"/app/counselor/" + counsellors[dataIndex].app_id}
                >
                  <Info />
                </Button>
              </Tooltip>
            ) : (
              ""
            )} */}
            {userData.role !== 2 && chkEditRoutPerm ? (
              counsellors[dataIndex].couns_name === null ? (
                <Tooltip disableFocusListener title="Assign Now">
                  <Button
                    size="small"
                    value={counsellors[dataIndex].app_id}
                    color="primary"
                    onClick={(event) => {
                      handleClickOpen(event, counsellors[dataIndex].email);
                    }}
                  >
                    <PersonAddOutlinedIcon />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip disableFocusListener title="Unassign">
                  <Button
                    size="small"
                    value={counsellors[dataIndex].app_id}
                    color="primary"
                    onClick={handleClickUnassign}
                  >
                    <PersonAddDisabledOutlinedIcon />
                  </Button>
                </Tooltip>
              )
            ) : ["in_progress", "unassigned"].includes(currntTabsValue) &&
              userData.role === 2 &&
              chkEditRoutPerm ? (
              counsellors[dataIndex].couns_name === null ? (
                <Tooltip disableFocusListener title="Assign Now">
                  <Button
                    size="small"
                    value={counsellors[dataIndex].app_id}
                    color="primary"
                    onClick={(event) => {
                      handleClickOpen(event, counsellors[dataIndex].email);
                    }}
                  >
                    <PersonAddOutlinedIcon />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip disableFocusListener title="Unassign">
                  <Button
                    size="small"
                    value={counsellors[dataIndex].app_id}
                    color="primary"
                    onClick={handleClickUnassign}
                  >
                    <PersonAddDisabledOutlinedIcon />
                  </Button>
                </Tooltip>
              )
            ) : (
              ""
            )}
            {/* <Tooltip disableFocusListener title="Status Log">
              <Button
                size="small"
                color="primary"
                component={RouterLink}
                to={`/app/statuslog/show/${counsellors[dataIndex].app_id}`}
              >
                <DescriptionOutlinedIcon />
              </Button>
            </Tooltip> */}

            <Tooltip disableFocusListener title="Download PDF">
              <Button
                color="primary"
                size="small"
                disabled={
                  counsellors[dataIndex].is_documents_signed === "Yes"
                    ? false
                    : true
                }
                href={
                  appConst.API_URL +
                  "../storage/app/public/application/" +
                  counsellors[dataIndex].app_id +
                  "/documents/signed/zip/" +
                  counsellors[dataIndex].application_code +
                  "-Signed-Documents.zip"
                }
                download
              >
                <Download />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    },
    {
      name: "application_date",
      label: "CREATION DATE",
      options: {
        filter: false,
        sort: true,
        // sortThirdClickReset: true,
        sortDirection: "desc",
        customBodyRenderLite: (dataIndex) =>
          counsellors[dataIndex].application_date
            ? moment(counsellors[dataIndex].application_date).format(
                "MM/DD/YYYY"
              )
            : "",
        sortCompare: (order) => {
          return (obj1, obj2) => {
            var val1 = moment(obj1.data).format("x");
            var val2 = moment(obj2.data).format("x");
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "application_code",
      label: "APPLICATION ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "user_name",
      label: "PURCHASER",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "PURCHASER'S EMAIL",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "application_type",
      // label: "Application-Type",
      label: "SERVICE TYPE",
      options: {
        filter: true,
        sort: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Pre-Need", "At-Need"],
        },
        sortDirection: "desc",
      },
    },
    {
      name: "deceased",
      label: "DECEASED",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value || <p style={{textAlign:"center"}}>—</p> 
      },
    },
    // {
    //   name: "deceased_email",
    //   label: "DECEASED'S EMAIL",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => value || <p style={{textAlign:"center"}}>—</p> 
    //   },
    // },

    {
      name: "couns_name",
      label: "COUNSELOR",
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: "process_status",
    //   label: "Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: "is_documents_signed",
      label: "APPLICATION SIGNED",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {showCounsModel ? (
        <UpdateCounsellorStatus
          popupStatus={showCounsModel}
          hidePopupStatus={setShowCounsModel}
          appCounsStatus={appCounsStatus}
          setAppCounsStatus={setAppCounsStatus}
          counsellorList={counsellors}
          fetchCounsellor={Counsellor}
          userRole={userData.role}
        />
      ) : (
        ""
      )}

      <PerfectScrollbar>
        <Box minWidth={1050}>
          <MUIDataTable
          className="custom-mui-table-main"
            title=""
            data={filteredData}
            // data={filteredData.length > 0 ? filteredData : counsellors} //all data show
            // data={counsellors} //without fillter
            columns={columns}
            options={options}
          />
        </Box>
      </PerfectScrollbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Counselor List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="counsellor-native-label-placeholder">
                Counselor
              </InputLabel>
              <NativeSelect
                onChange={handleChange}
                inputProps={{
                  name: "couns_id",
                  id: "counsellor-native-label-placeholder",
                }}
              >
                <option value="">Select Counselor</option>
                {counsellorsDataArr !== null ? (
                  counsellorsDataArr.map((o) =>
                    o.email !== appUserEmail ? (
                      <option key={o.couns_id} value={o.couns_id}>
                        {o.name}
                      </option>
                    ) : null
                  )
                ) : (
                  <option value="0">No Record Found.</option>
                )}
              </NativeSelect>
            </FormControl>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  counsellors: PropTypes.array.isRequired,
};

export default Results;
