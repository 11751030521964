import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Page from "src/components/Page";
import { getItem, setItem } from "src/services/Helper";
import { call } from "src/services/Http";
import { LayoutOne } from "lotuscremationlibrary";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PreQuestListView from "../PreQuestListView";

export default function PreQuestView({
  setActiveTab,
  setdisableTab,
  setAllButtonDisable,
  setDocusign_url,
  setApplicationId,
  isPreQuest,
  token,
  bgImage,
  mode,
  ...props
}) {
  let navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const getQuestion = async (token) => {
    await call("POST", "guest/questionnaire", {
      type: 0,
      device_id: token,
      is_last_attended: parseInt(await getItem("stage")),
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data);
        setProgress(
          ((response.data.progressbar.index - 1) /
            response.data.progressbar.total) *
            100
        );
      } else if (response.status === 400) {
        localStorage.setItem("stage", 1);
        // setActiveTab("step2");
      }
    });
  };

  const postQuestion = async (answer, actions, is_next, is_skip = false) => {
    props.setLoading(true);
    await call("POST", "guest/questionnaire", {
      type: 0,
      device_id: token,
      answer: answer.answer,
      current_question_id: data.question.question_id,
      is_next: is_next,
      is_string: [1, 4, 13, 14].includes(data.question.control),
      address:
        is_next && answer.address !== undefined && is_skip === false
          ? answer.address
          : "",
    }).then(async (response) => {
      if (response.status === 200) {
        await setData(response.data);
        setProgress(
          ((response.data.progressbar.index - 1) /
            response.data.progressbar.total) *
            100
        );
      } else if (response.status === 400) {
        localStorage.setItem("stage", 1);
        props.getPreQueData();
        /* getPreQueData().then(async () => {
          if (DisableTab === 1) {
            setActiveTab("step2");
          } else if (DisableTab === 2) {
            setActiveTab("step3");
          } else if (DisableTab === 3) {
            setActiveTab("step6");
          } else if (DisableTab === 4) {
            if (isPreneed) {
              setActiveTab("step5");
            } else {
              setActiveTab("step6");
            }
          }
        });*/
        // setActiveTab("step2");
      }
      props.setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getQuestion(token).then(()=>{
      setLoading(false);
    });
  }, []);

  return (
    <Page title="Fernwood Cemetery pre-quotes">
      <Grid
        container
        justifyContent="center"
        style={{
          padding: 25,
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          zIndex: 0,
          backgroundPosition: "center",
          position: "relative",
        }}
        className="dynamic-body"
      >
        {isPreQuest === true ? (
          <PreQuestListView />
        ) : (
          <LayoutOne
            data={data}
            loading={isLoading}
            onSubmitForm={postQuestion}
            progress={progress}
            mode={mode}
          />
        )}
      </Grid>
    </Page>
  );
}
