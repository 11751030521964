import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import * as Http from "src/services/Http";
import * as Messages from "src/constants/Messages/index";
import Cancelbtn from "src/components/common/Cancelbtn";
// import RichEditor from "src/components/RichEditor";
import { CKEditor } from "ckeditor4-react";
import FormFieldDetails from "./FormFieldDetails";

const AddDocumentFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),

  field_array: Yup.array(
    Yup.object().shape({
      field_name: Yup.string().required(Messages.REQUIRED),
      x_point: Yup.number()
        .typeError(Messages.ONLY_NUM)
        .required(Messages.REQUIRED),
      y_point: Yup.number()
        .typeError(Messages.ONLY_NUM)
        .required(Messages.REQUIRED),
      page_no: Yup.number()
        .typeError(Messages.ONLY_NUM)
        .required(Messages.REQUIRED),
    })
  ),

  // field_array: Yup.array()
  //   .of(Yup.string().required(Messages.REQUIRED))
  //   .min(1, Messages.MINONEREQUIRED),
});

const DocumentFormDetails = (props) => {
  const navigate = useNavigate();
  const { mode, formValues, setSnackbar } = props;
  const initialValues = {
    id: formValues.id,
    name: formValues.name,
    editor: formValues.editor,
    field_array:
      formValues.field_array.length > 0
        ? formValues.field_array
        : [{ field_name: "", x_point: "", y_point: "", page_no: "" }],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddDocumentFormSchema}
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/forms";
        } else {
          method = "PATCH";
          url = "admin/forms/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              navigate("/app/document-form");
            } else if (status === 422) {
              if (data.name) {
                actions.setFieldError("name", data.name[0]);
              }
            }
            setSnackbar(true, status, message);
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                subheader={
                  "The information can be " +
                  mode.toLowerCase() +
                  " document form"
                }
                title={mode + " Document form"}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="name"
                      label="Form Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      margin="normal"
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <CardHeader title="Form Description" />
                    <CKEditor
                      name="editor"
                      config={{
                        extraPlugins: "docprops",
                        removePlugins: "about,image,easyimage",
                        // imageUploadUrl:
                        //"/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json",
                        //cloudServices_uploadUrl:
                        // "https://33333.cke-cs.com/easyimage/upload/",
                        // cloudServices_tokenUrl:
                        //   "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                        fullPage: true,
                        allowedContent: true,
                        formatTags: "p;h1;h2;h3;pre;span;",
                        height: 320,
                      }}
                      initData={values.editor}
                      onBlur={(event) => {
                        setFieldValue("editor", event.editor.getData());
                      }}
                      onChange={(event) => {
                        setFieldValue("editor", event.editor.getData());
                      }}
                    />
                    {errors.editor && touched.editor && (
                      <div style={{ color: "red", margin: ".8rem 0" }}>
                        {errors.editor}
                      </div>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormFieldDetails
                      nameval={values.field_array}
                      labelval="Form Fields"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touchTextval={touched.field_array}
                      errorTextval={errors.field_array}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default DocumentFormDetails;
