import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import SettingsForm from "./SettingsForm";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import { setSnackbar } from "src/actions";
import ScreenLoader from "src/components/ScreenLoader";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Toolbar from "./Toolbar";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Setting", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  settingDetail: {
    marginTop: theme.spacing(3),
  },
}));

const SettingsView = (props) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    facebook_link: "https://facebook.com",
    linkedIn_link: "https://in.linkedin.com",
    twitter_link: "https://twitter.com",
    instagram_link: "https://www.instagram.com",
    api_key: "123456789",
    api_secret: "secret 123",
    prequestionnaire_remider_duration: "6",
    prequestionnaire_unit: "1",
    postquestionnaire_remider_duration: "12",
    postquestionnaire_unit: "2",
    tax_rate: "100",
    free_miles: "",
    mile_charges: "",
    admin_address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    long: "",
    lat: "",
    greate_western_email: "",
    stripe_api_key: "",
    stripe_api_secret: "",
    primary_payment_method: 0,
  });
  const authuser = useSelector((state) => state?.session?.user);
  useEffect(() => {
    fetchSetting();
  }, []);
  const fetchSetting = async () => {
    const { data } = await Http.call("GET", "admin/site_setting");
    setValues({
      facebook_link: data.facebook_link,
      linkedIn_link: data.linkedIn_link,
      twitter_link: data.twitter_link,
      instagram_link: data.instagram_link,
      api_key: data.api_key,
      api_secret: data.api_secret,
      prequestionnaire_remider_duration: data.prequestionnaire_remider_duration,
      prequestionnaire_unit: data.prequestionnaire_unit,
      postquestionnaire_remider_duration:
        data.postquestionnaire_remider_duration,
      postquestionnaire_unit: data.postquestionnaire_unit,
      tax_rate: data.tax_rate,
      free_miles: data.free_miles,
      mile_charges: data.mile_charges,
      admin_address: data.admin_address,
      country: data.country,
      state: data.state,
      city: data.city,
      zip: data.zip,
      long: data.long,
      lat: data.lat,
      greate_western_email: data.greate_western_email,
      stripe_api_key: data.stripe_api_key,
      stripe_api_secret: data.stripe_api_secret,
      primary_payment_method:
        data.primary_payment_method !== ""
          ? parseInt(data.primary_payment_method)
          : 0,
    });
    setLoading(false);
  };
  const handleSubmit = async (values) => {
    console.log(values, "values");
    await Http.call("POST", "admin/site_setting/update", values).then(
      (response) => {
        props.setSnackbar(true, response.status, response.message);
      }
    );
  };
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar />
        <Box mt={3} className={classes.settingDetail}>
          <SettingsForm
            formValues={values}
            updateSetting={handleSubmit}
            authuser={authuser}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
