import React, { useState, useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Toolbar from "./Toolbar";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Contacts", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    width: "40%",
    maxHeight: 435,
  },
}));

const ContactsListView = (props) => {
  const [userList, setUserList] = useState([]);
  const [Loading, setLoading] = useState(true);
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);
  const fetchUserList = async () => {
    try {
      const response = await Http.call("GET", "admin/lookup_dirs");
      setUserList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const multipleDelete = async (data, isContactDelete) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/lookup_dirs/delete", {
        id: data,
        iscontactdelete: isContactDelete,
      }).then(async (response) => {
        fetchUserList();
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Contacts">
      <Container maxWidth="xl">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <Results
            users={userList}
            pageLoading={setLoading}
            fetchUser={fetchUserList}
            multipleDelete={multipleDelete}
            authuser={authuser}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsListView);
